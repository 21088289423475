<template>
  <div class="lg:flex lg:items-center lg:justify-between">
    <div
      class="flex-1 min-w-0 grid grid-cols-12 bg-white border-t border-b px-5 mb-5"
    >
      <div class="col-span-3 pt-2">
        <h2
          v-if="Object.keys(customer).length > 0"
          class="text-2xl font-bold leading-7 text-gray-900 hover:text-gray-700 sm:text-3xl sm:truncate"
        >
          <router-link :to="`/customer-detail/${customer.id}`">
            {{ customer.name }}</router-link
          >
        </h2>
        <h2
          v-else
          class="text-xl font-bold leading-4 text-gray-900 hover:text-gray-700 sm:text-2xl sm:truncate"
        >
          <router-link :to="`/customer-detail/${customerData.id}`">
            {{ customerData.name }}</router-link
          >
        </h2>
        <div
          class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <LocationMarkerIcon
              class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {{
              Object.keys(customer).length > 0
                ? customer.mainAddressLine1
                : customerData.mainAddressLine1
            }}.
            {{
              Object.keys(customer).length > 0
                ? customer.mainAddressCity
                : customerData.mainAddressCity
            }}
          </div>
        </div>
      </div>

      <div class="lg:mt-0 lg:ml-4 col-span-9 mb-2">
        <div class="grid grid-cols-12 h-auto body__header mt-2 w-auto">
          <div class="col-span-6 col-start-7 pl-5 pr-5 mb-1 pb-1">
            <div class="z-20 rounded-md px-3" ref="contactsList">
              <Listbox as="div" v-model="selected">
                <ListboxLabel class="block text-sm font-medium text-gray-700">
                  {{ $t("ticket.assignedContacts") }}
                </ListboxLabel>
                <div class="mt-1 relative">
                  <ListboxButton
                    class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default encom_primary_border_focus sm:text-sm"
                  >
                    <span class="block truncate">{{
                      $t("ticket.addAssignedContact")
                    }}</span>
                    <span
                      class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                    >
                      <SelectorIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="worker in coworkers"
                        :key="worker.id"
                        :value="worker"
                        v-slot="{ active }"
                      >
                        <li
                          :class="[
                            active ? 'text-white sidebar' : '',
                            'cursor-default select-none relative py-2 pl-3 pr-9',
                          ]"
                        >
                          <a
                            @click="putContacts(worker.id)"
                            class="cursor-pointer "
                          >
                            <span class="font-normal block truncate">
                              {{ worker.firstName }}
                              {{ worker.lastName }}
                            </span>

                            <span
                              v-show="
                                checkArrayForContact(worker.id) ||
                                  checkAssociatedContacts(worker.id)
                              "
                              :class="[
                                active
                                  ? 'text-white'
                                  : 'body__table-header-filter--text',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </a>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>
        </div>
      </div>

      <div class="border-t border-gray-200 px-4 py-3 sm:px-6 col-span-12">
        <ul
          role="list"
          class="flex flex-row-reverse gap-2 sm:grid-cols-2 lg:grid-cols-6"
        >
          <li
            v-for="person in ticketContacts"
            :key="person.id"
            class="contact_card col-span-1 bg-gray-100 border rounded-lg"
          >
            <div
              class="grid grid-cols-12 w-full flex items-center justify-between pl-2 pr-3 py-1"
            >
              <div class="flex-1 truncate col-span-10">
                <div class="flex items-center space-x-3">
                  <h3
                    class="text-gray-900 capitalize text-sm font-medium truncate"
                  >
                    {{ person.firstName }} {{ person.lastName }}
                  </h3>
                  <span
                    v-if="person.technicalContact === 1"
                    class="flex-shrink-0 inline-block px-1 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full"
                    >Technical</span
                  >
                </div>
                <a
                  :href="`mailto:${person.emailAddress}`"
                  class="mt-1 text-gray-700 text-sm truncate lowercase"
                >
                  {{ person.emailAddress }}
                </a>
                <div
                  class="flex"
                  v-if="person.technicalContact === 1 && person.landlinePhone"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 mt-1.5 mr-1 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <p class="mt-1 text-gray-700 text-sm truncate">
                    {{ person.landlinePhone }}
                  </p>
                </div>
                <div
                  class="flex"
                  v-if="person.technicalContact === 1 && person.mobilePhone"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 mt-1.5 mr-1 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                    />
                  </svg>
                  <p class="mt-1 text-gray-700 text-sm truncate">
                    {{ person.mobilePhone }}
                  </p>
                </div>
              </div>
              <div
                class="-mt-px flex divide-x divide-gray-200 col-start-12 self-start"
              >
                <ul role="list" class="space-y-1">
                  <li>
                    <button
                      class="p-1 shadow-sm border border-gray-400 text-gray-800 rounded-md hover:bg-gray-50 cursor-pointer tooltip inline-flex"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </li>
                  <li>
                    <a
                      @click="putContacts(person.id)"
                      href="javascript:void(0)"
                      title="Take contact out of the ticket"
                      class="p-1 shadow-sm border border-gray-400 text-gray-800 rounded-md hover:bg-gray-50 cursor-pointer tooltip inline-flex"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                        /></svg
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import {
  CheckIcon,
  SelectorIcon,
  LocationMarkerIcon,
  PhoneIcon,
} from "@heroicons/vue/solid";
import { SearchIcon } from "@heroicons/vue/outline";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
const people = [
  { id: 1, name: "Wade Cooper" },
  { id: 2, name: "Arlene Mccoy" },
  { id: 3, name: "Devon Webb" },
  { id: 4, name: "Tom Cook" },
  { id: 5, name: "Tanya Fox" },
  { id: 6, name: "Hellen Schmidt" },
  { id: 7, name: "Caroline Schultz" },
  { id: 8, name: "Mason Heaney" },
  { id: 9, name: "Claudie Smitham" },
  { id: 10, name: "Emil Schaefer" },
];
export default {
  props: [
    "contactId",
    "ticketId",
    "ticketContacts",
    "customerData",
    "contactId",
    "coworkers",
  ],
  components: {
    CheckIcon,
    SelectorIcon,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    SearchIcon,
    LocationMarkerIcon,
    PhoneIcon,
  },
  setup() {
    const selected = ref(people[3]);

    return {
      people,
      selected,
    };
  },
  data() {
    return {
      customerId: "",
      customer: {},
      searchContact: "",
      pushContacts: [],
      searchActive: false,
      isCustomerInfo: false,
      isSearchCustomer: false,
    };
  },
  methods: {
    pushContactToArray(id, fName, lName) {
      let contact = {
        id: id,
        fName: fName,
        lName: lName,
      };
      let contactIndex = "";

      let hasContact = false;
      for (let index = 0; index < this.pushContacts.length; index++) {
        if (this.pushContacts[index].id == contact.id) {
          hasContact = true;
          contactIndex = index;
        }
      }
      if (hasContact) {
        this.pushContacts.splice(contactIndex, 1);
      } else {
        this.pushContacts.push(contact);
      }
      if (!this.pushContacts.length) {
        this.searchContact = "";
      }
    },
    checkArrayForContact(id) {
      let hasContact = false;
      for (let index = 0; index < this.pushContacts.length; index++) {
        if (this.pushContacts[index].id == id) {
          hasContact = true;
        }
      }
      return hasContact;
    },
    checkAssociatedContacts(id) {
      let isAssociated = false;
      if (this.ticketContacts) {
        for (let index = 0; index < this.ticketContacts.length; index++) {
          if (this.ticketContacts[index].id == id) {
            isAssociated = true;
          }
        }
      }
      return isAssociated;
    },
    async getContacts() {
      if (this.ticketId) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/tickets/${this.ticketId}`
          );
          this.customerId = res.data.ticketable_id;
          this.getCustomer(res.data.ticketable_id);
        } catch (error) {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
        }
      }
    },
    async getCustomer(id) {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/customers/${id}`
        );
        this.customer = res.data;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
      }
    },

    putContacts(id) {
      this.searchActive = false;
      let contactsId = [];
      let unassociateId = [];
      // for (let index = 0; index < this.pushContacts.length; index++) {
      //   contactsId.push(this.pushContacts[index].id);
      // }
      if (id) {
        if (this.checkAssociatedContacts(id)) {
          if (this.ticketContacts.length > 1) {
            this.$emit("removeContactOpenTicket", id);
            unassociateId.push(id);
          } else {
            this.$emit("activeBanner");
            this.$emit(
              "successMsg",
              "The ticket must have a contact associated to it."
            );
            this.$emit("success", false);
          }
        } else {
          this.$emit("addContactOpenTicket", id);
          contactsId.push(id);
        }
        if (this.ticketId) {
          try {
            const options = {
              method: "PUT",
              url: `${this.$cookie.getCookie("API")}/api/v1/tickets/${
                this.ticketId
              }/associates`,
              data: { associated: contactsId, unassociated: unassociateId },
            };

            axios
              .request(options)
              .then((response) => {
                this.$emit("getContacts", this.ticketId);
                this.$forceUpdate();
              })
              .catch((error) => {
                console.error(error);
                if (error.response.status == 401) {
                  localStorage.removeItem("token");
                  this.$store.dispatch("token", null);
                  this.$router.push("/login");
                }
              });
          } catch (error) {
            console.error(error);
            if (error.response.status == 401) {
              localStorage.removeItem("token");
              this.$store.dispatch("token", null);
              this.$router.push("/login");
            }
          }
        }
      }
    },
  },
  mounted() {
    this.getContacts();
  },
};
</script>

<style>
.contact_card {
  max-width: 250px;
}
.max_height {
  max-height: 31vh;
}
</style>
